import LessonData from "../lessonData";


function GroupDetailsBig(props) {

  // Define variables
  const id = props.id;
  const learnerCount = props.learnerCount;
  const totalSeats = props.totalSeats;
  const lessonsCompleted = props.lessonsCompleted;
  const totalLearningHours = props.totalLearningHours;
  const averageScore = props.averageScore;
  const averageRetries = props.averageRetries;
  const adminNames = props.adminNames;
  const registrationLink = props.registrationLink;
  const lessonOverTimeData = props.lessonOverTimeData;
  const curriculum = props.curriculum;
  const createdDate = props.createdDate;


  // Screen components
  let totalSeatsElement;
  if( totalSeats ) {
    totalSeatsElement = <small> of {totalSeats} seats</small>;
  }


  return (
    <div class='flex-row group-details-container'>

      <div class='flex-column group-details-left'>

        <div>
          <strong>Curriculum</strong>
          <p>{curriculum}</p>
        </div>

        <div>
          <strong>Managers</strong>
          <p>{adminNames ? adminNames : <small><i>There are not any Managers assigned yet</i></small>}</p>
        </div>

        <div>
          <strong>Group ID</strong>
          <p>{id}</p>
        </div>

        <div>
          <strong>Created Date</strong>
          <p>{createdDate}</p>
        </div>

        <div class='registration-link-container'>
          <strong>Registration Link</strong>
          <p class='smaller' style={{lineBreak: 'anywhere'}}>
            {registrationLink}
            &nbsp;&nbsp;
            <button
              type='button'
              onClick={() => alert('Send this link to your students or coworkers. When they signup through this link, they will automatically be associated with this Group.')}
            >
              <span class='icon icon-info'></span>
            </button>
          </p>
        </div>

      </div>


      <div class='flex-row group-details-right'>

        <div class='flex-column group-details-right-left'>
          <div class='flex-column stat-box'>
            <p class='stat-label'>Learners</p>
            <h4>{learnerCount}</h4>
            {totalSeatsElement}
          </div>

          <div class='flex-column stat-box'>
            <p class='stat-label'>Lessons</p>
            <h4>{lessonsCompleted}</h4>
            <small>completed</small>
          </div>
        </div>

        <div class='flex-column group-details-right-right'>

          <div class='flex-row top-stats'>

            <div class='flex-column stat-box'>
              <p class='stat-label'>Learning Time</p>
              <h4>{totalLearningHours}</h4>
              <small>hours</small>
            </div>

            <div class='flex-column stat-box'>
              <p class='stat-label'>Average Score</p>
              <h4>{averageScore}</h4>
              <small>&nbsp;</small>
            </div>

            <div class='flex-column stat-box'>
              <p class='stat-label'>Average Retries</p>
              <h4>{averageRetries}</h4>
              <small>&nbsp;</small>
            </div>

          </div>

          <LessonData data={lessonOverTimeData} />

        </div>

      </div>

    </div>
  );
}

export default GroupDetailsBig;