import React from 'react';
import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import toast from 'react-hot-toast';
import apiCall from '../../helperFunctions/apiCall';
import { isAuthed } from '../utilities/auth/tokenHelpers';
import { resetPassword } from '../utilities/auth/authAPI';
import Header from '../utilities/header';
import InputPassword from '../utilities/forms/inputPassword';
import ButtonPrimary from '../utilities/buttonPrimary';


function ResetPasswordScreen() {

  // Define variables
  const navigate = useNavigate();
  const [password, setPassword] = useState('');
  const [message, setMessage] = useState('');
  const [buttonLoading, setButtonLoading] = useState('');

  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const token = urlParams.get('reset_password_token');


  // Page load actions
  useEffect(() => {

    const loggedIn = isAuthed();
    if( loggedIn ) {
      navigate('/');
    }

  }, []);


  // Define functions
  function handleSubmit(event) {
    event.preventDefault();

    if( !password ) {
      toast.error('Please enter a password');
      return;
    }

    setButtonLoading(true);

    const payload = {
      token: token,
      password: password
    };


    apiCall(resetPassword, payload).then((response) => {
      if( response.success ) {
        const newMessage = <p>Password successfully reset. <u><a href='/login'>You can now login</a></u>.</p>
        setMessage(newMessage);
      } else {
        let error = response.error;
          if( error === 'token_error' ) {
            error = (
              <div class='error-box'>
                Sorry, the reset password token is invalid or expired. You can visit the&nbsp;
                <u><a href='/forgot-password'>forgot password</a></u> page or contact support@flowspeak.io.
              </div>
            );
          }

        setMessage(error);
        setTimeout(() => setButtonLoading(null), 400);
      }
    });
  }


  return(
    <div class='flex-row start-wrapper'>

      <div class='start-left-column'>
        <div class='start-left-inner'></div>
      </div>

      <div class='start-right-column'>
        <div class='flex-column start-right-inner'>

          <Header />

          <form onSubmit={handleSubmit}>

            <h2 class='outfit'>Reset Password</h2>
            <p class='p-secondary'>Enter your new password</p>
            <div class='field'>
              <InputPassword
                value={password}
                handleOnChange={setPassword}
              />
            </div>

            <div style={{marginTop: '30px', minHeight: '30px'}}>
              {message}
            </div>

            <div style={{marginTop: '60px'}}>
              <ButtonPrimary
                value={'Reset Password'}
                loading={buttonLoading}
                buttonClass={'button tertiary'}
              />

            </div>


            <div class='start-link-container'>
              <p>Already have an account?</p>
              <p>
                <a href='/login'>Login to your FLOW Administrator account</a>
              </p>
            </div>

          </form>

          <div style={{height: '80px', width: '1px'}}></div>

        </div>
      </div>
    </div>
  );
}

export default ResetPasswordScreen;