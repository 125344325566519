import { useState } from 'react';

import toast from 'react-hot-toast';
import apiCall from '../../../helperFunctions/apiCall';
import { onboardingUpdate } from '../../utilities/auth/authAPI';

import ButtonPrimary from '../../utilities/buttonPrimary';
import CourseChooser from './courseChooser';

import onboardWording from '../../../helperFunctions/onboardWording';



function StepTwo(props) {

  // Define variables
  const [payload, setPayload] = useState({step: 2, curriculum: 'pathways'});
  const [buttonLoading, setButtonLoading] = useState(false);
  // const [courseList, setCourseList] = useState([]);
  const updateStep = props.updateStep;
  const orgType = props.orgType;

  const wordingGroupName = onboardWording(orgType, 'wordingGroupName');
  const studentWording = onboardWording(orgType, 'student');


  // Define functions
  function handleChange(value, field) {
    setPayload({...payload, [field]: value});
  }

  function handleSubmit(event) {
    event.preventDefault();

    if( !payload.groupName || !payload.curriculum || (payload.curriculum === 'course' ? !payload.courseId : false) ) {
      toast.error('Please enter your Group name and choose your Curriculum');
      return;
    }

    setButtonLoading(true);


    apiCall(onboardingUpdate, payload).then((response) => {
      if( response.success ) {
        updateStep(3);
      } else {
        toast.error(response.error);
      }
    }).finally(() => {
      setTimeout(() => setButtonLoading(null), 400);
    });
  }


  // Screen components
  let courseChooserElement;
  if( payload.curriculum === 'course' ) {
    courseChooserElement = (
      <CourseChooser
        // courseList={courseList}
        // setCourseList={setCourseList}
        handleChange={handleChange}
        selectedId={payload.courseId}
        studentWording={studentWording}
      />
    );
  }


  return (
    <form onSubmit={handleSubmit}>

      <div class='field with-bottom-margin'>
        <h3 class='v2'>Group name</h3>
        <p class='p-secondary'>{wordingGroupName}</p>
        <input
          name="groupName"
          type="text"
          value={payload.groupName}
          onChange={event => handleChange(event.target.value, 'groupName')}
          required
          placeholder='Group name'
          title="Enter your Group's name"
          style={{marginTop: '10px'}}
        />
      </div>

      <div class='field with-bottom-margin'>
        <h3 class='v2'>Curriculum</h3>
        <p class='p-secondary'>Don't worry, you can change this later.</p>

        <div class='flex-row onboarding-big-buttons-wrapper' style={{marginTop: '10px'}}>

            <button
              type='button'
              class={`onboarding-big-button ${payload.curriculum === 'pathways' ? 'selected' : ''}`}
              onClick={() => handleChange('pathways', 'curriculum')}
            >
              <p class='p-secondary'><small><i>Default</i></small></p>
              <h3>Pathways</h3>
              <p>&nbsp;</p>
            </button>

            <button
              type='button'
              class={`onboarding-big-button ${payload.curriculum === 'course' ? 'selected' : ''}`}
              onClick={() => handleChange('course', 'curriculum')}
            >
              <h3>Course</h3>
            </button>

        </div>

        <div class='flex-row onboarding-big-buttons-wrapper'>

          <div class='onboarding-big-button sub-text'>
            <p class='p-secondary' style={{lineHeight: '1.2rem'}}>
              <small>
                Structured learning tracks to guide learners. Each Pathway has three Levels that are 8 weeks long.
                <br />
                <br />
                Your {studentWording} can choose the Beginner, Intermediate, or Advanced Pathway. Learn more.
              </small>
            </p>
          </div>

          <div class='onboarding-big-button sub-text'>
            <p class='p-secondary' style={{lineHeight: '1.2rem'}}>
              <small>
                Choose from one of pre-made Courses to give your {studentWording} a specific topic to foucs on.
              </small>
            </p>
          </div>

        </div>

        {courseChooserElement}
      </div>



      <div style={{marginTop: '60px'}}>
        <ButtonPrimary
          value={'Continue'}
          loading={buttonLoading}
          // disabled={!payload.groupName || !payload.curriculum || (payload.curriculum === 'course' ? !payload.courseId : false)}
          buttonClass={'button tertiary'}
        />
      </div>

    </form>
  );
}

export default StepTwo;