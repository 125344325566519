function onboardWording(orgType, wording) {
  const isBusiness = findIsBusiness(orgType);

  let functionToCall;
  if( wording === 'wordingGroupName' ) { functionToCall = wordingGroupName }
  if( wording === 'student' ) { functionToCall = student }
  if( wording === 'teacher' ) { functionToCall = teacher }

  return functionToCall(isBusiness);
}

export default onboardWording;




function findIsBusiness(orgType) {
  let isBusiness = false;
  if( orgType === 'Business' || orgType === 'Other' ) { isBusiness = true }

  return isBusiness;
}



function wordingGroupName(isBusiness) {
  let wording = "Let's create your first group. A Group could be a class name or a cohort name."
  if( isBusiness ) { wording = "Let's create your first group. A Group could be a team name or a department name." }
  return wording;
}

function student(isBusiness) {
  let wording = "students"
  if( isBusiness ) { wording = "team" }
  return wording;
}

function teacher(isBusiness) {
  let wording = "teacher"
  if( isBusiness ) { wording = "admin" }
  return wording;
}