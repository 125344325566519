function CourseChooseButton(props) {

  // Define variables
  const course = props.course;
  const handleChange = props.handleChange;
  const selectedId = props.selectedId;


  // Screen components
  let premiumElement;
  if( course.premium ) {
    premiumElement = <p class='premium-message'>Premium plan required</p>;
  } else {
    premiumElement = <p class='premium-message'>Available for Community and Premium plans</p>;
  }


  return (
    <button
      type='button'
      class={`course-choose-button ${course.id === selectedId ? 'selected' : ''}`}
      onClick={() => handleChange(course.id, 'courseId')}
    >
      <h5 class='outfit'>{course.title}</h5>
      <p>{course.description}</p>
      <p class='course-description'>{course.duration}, {course.lessonCount} lessons, {course.level} Level</p>
      {premiumElement}
    </button>
  )
}

export default CourseChooseButton;