import { useState } from "react";

import GroupDetailsShort from "./groupDetailsShort";
import GroupDetailsBig from "./groupDetailsBig";


function GroupDetails(props) {

  // Define variables
  const [expand, setExpand] = useState(false);
  const group = props.group;
  const id = group.id;
  // const startDate = group.startDate;
  // const endDate = group.endDate;
  // const promoCode = group.promoCode;
  const registrationLink = group.registrationLink;
  const learnerCount = group.learnerCount;
  const adminNames = group.adminNames;
  const activityData = group.activityData;
  // const slug = group.slug;
  const totalSeats = group.totalSeats;
  const curriculum = group.curriculum;
  const createdDate = group.createdDate;

  let lessonsStarted = 0;
  let lessonsCompleted = 0;
  let averageRetries = 0;
  let averageScore = 0;
  let lessonOverTimeData;
  let totalLearningTime;
  let totalLearningMinutes;
  let totalLearningHours;

  if( activityData ) {
    lessonsStarted = activityData.lessonsStarted;
    lessonsCompleted = activityData.lessonsCompleted;
    averageRetries = activityData.averageRetries;
    averageScore = activityData.averageScore;
    lessonOverTimeData = activityData.lessonOverTimeData;
    totalLearningTime = activityData.totalLearningTime;
    totalLearningMinutes = Math.round(totalLearningTime / 60);
    totalLearningHours = (totalLearningMinutes / 60).toFixed(1);
  }


  // Screen components
  let bodyElement;
  if( expand ) {
    bodyElement = (
      <GroupDetailsBig
        id={id}
        learnerCount={learnerCount}
        totalSeats={totalSeats}
        lessonsCompleted={lessonsCompleted}
        totalLearningHours={totalLearningHours}
        averageScore={averageScore}
        averageRetries={averageRetries}
        adminNames={adminNames}
        registrationLink={registrationLink}
        lessonOverTimeData={lessonOverTimeData}
        curriculum={curriculum}
        createdDate={createdDate}
      />
    );
  } else {
    bodyElement = (
      <GroupDetailsShort
        learnerCount={learnerCount}
        totalSeats={totalSeats}
        lessonsCompleted={lessonsCompleted}
        totalLearningHours={totalLearningHours}
        averageScore={averageScore}
        averageRetries={averageRetries}
      />
    );
  }


  return (
    <div class='group-details-wrapper'>
      <div class='flex-row'>
        <h3>Details</h3>
        <button
          type='button'
          class='flex-row'
          onClick={() => setExpand(!expand)}
        >
          <p class='p-secondary small' style={{marginBottom: 'initial', marginRight: '5px'}}>{expand ? 'collapse' : 'expand'}</p>
          <span class={`icon ${expand ? 'icon-chevron-up' : 'icon-chevron-down'}`} style={{width: '12px', opacity: '.8'}}></span>
        </button>
      </div>
      {bodyElement}
    </div>
  );
}

export default GroupDetails;