import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import Nav from './nav';


function Header() {

  // Define variables
  const navigate = useNavigate();
  const profileData = useSelector((state) => state.profile);
  const isSuperAdmin = useSelector((state) => state.profile.role) === 'superAdmin';


  // Screen componets
  let logoElement;
  let navElement;

  if( profileData.initalized ) {
    logoElement = (
      <button
          class='flex-row header-logo'
          onClick={() => navigate('/')}
        >
          <div class='logo-lockup'></div>
          <p class='header-admin'>Administrator</p>
      </button>
    );

    navElement = (
      <Nav
        profileData={profileData}
        isSuperAdmin={isSuperAdmin}
      />
    );

  } else {
    logoElement = (
      <div class='flex-row header-logo'>
        <div class='logo-lockup'></div>
        <p class='header-admin'>Administrator</p>
      </div>
    );
  }


  return(
    <div class='header flex-column'>
      <div class='header-container flex-row'>
        {logoElement}
        {navElement}
      </div>
    </div>
  );
}

export default Header;