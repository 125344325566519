import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';

import apiCall from '../../helperFunctions/apiCall';
import { verifyCheckoutSession } from '../utilities/auth/authAPI';
let canSubmit = true;


function SeatsPurchaseCompleteScreen() {

  // Define variables
  const { sessionId } = useParams();
  const navigate = useNavigate();


  // Component load actions
  useEffect(() => {
    if( canSubmit ) {
      canSubmit = false;

      apiCall(verifyCheckoutSession, {sessionId: sessionId}).then((response) => {
        if( response.success ) {
          navigate('/seats?success=true');
        } else {
          navigate(`/seats?success=false&error=${response.error}`);
        }
      });
    }
  }, []);


  return (
    <></>
  );
}

export default SeatsPurchaseCompleteScreen;