import { createSlice, PayloadAction } from '@reduxjs/toolkit';

const initialState = {
  initalized: false,
  firstName: 'Loading...',
  role: null,
  organizationId: null,
  organizationName: 'Loading...',
  activityData: null,
  organizations: [],
  groups: [
    {
      name: 'Loading...'
    }
  ],
  assessmentsEnabled: false,
  invitesEnabled: false
};

export const name = 'profile';

const profileSlice = createSlice({
  name,
  initialState,
  // The `reducers` field allows us define reducers and generate associated actions
  reducers: {
    initialPopulate: (state, action) => {
      state.initalized = true;
      state.firstName = action.payload.firstName;
      state.role = action.payload.role;
      state.organizations = action.payload.organizations;
      state.organizationId = action.payload.organizationId;
      state.organizationName = action.payload.organizationName;
      state.activityData = action.payload.activityData;
      state.groups = action.payload.groups;
      state.assessmentsEnabled = action.payload.assessmentsEnabled;
      state.invitesEnabled = action.payload.invitesEnabled;

      // state.seatsEnabled = action.payload.seatsEnabled;
      // state.seatsTotal = action.payload.seatsTotal;
      // state.seatsUsed = action.payload.seatsUsed;

      // state.datesEnabled = action.payload.datesEnabled;
      // state.planStartDate = action.payload.planStartDate;
      // state.planEndDate = action.payload.planEndDate;

      if( action.payload.inviteData ) {
        state.inviteData = action.payload.inviteData;
      }

      if( action.payload.purchaseData ) {
        state.purchaseData = action.payload.purchaseData;
      }

    },
    clearInitialState: (state, action) => {
      state.initalized = false;
    }
  },
});

export const { actions, reducer } = profileSlice;
export default profileSlice;