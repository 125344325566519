import React from 'react';
import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import toast from 'react-hot-toast';
import apiCall from '../../helperFunctions/apiCall';
import { isAuthed } from '../utilities/auth/tokenHelpers';
import { forgotPassword } from '../utilities/auth/authAPI';
import Header from '../utilities/header';
import ButtonPrimary from '../utilities/buttonPrimary';


function ForgotPasswordScreen() {

  // Define variables
  const navigate = useNavigate();
  const [email, setEmail] = useState('');
  const [buttonLoading, setButtonLoading] = useState('');


  // Page load actions
  useEffect(() => {

    const loggedIn = isAuthed();
    if( loggedIn ) {
      navigate('/');
    }

  }, []);


  // Define functions
  function handleSubmit(event) {
    event.preventDefault();

    if( !email ) {
      toast.error('Please enter your email');
      return;
    }

    setButtonLoading(true);

    const payload = {
      email: email,
      location: 'admin'
    };

    apiCall(forgotPassword, payload).then((response) => {
      if( response.success ) {
        toast.success('Reset password email sent. Check your inbox for instructions.', {duration: 10000});
      } else {
        toast.error(response.error);
        setTimeout(() => setButtonLoading(null), 400);
      }
    });
  }


  return(
    <div class='flex-row start-wrapper'>

      <div class='start-left-column'>
        <div class='start-left-inner'></div>
      </div>

      <div class='start-right-column'>
        <div class='flex-column start-right-inner'>

          <Header />

          <form onSubmit={handleSubmit}>

            <h2 class='outfit'>Forgot Password</h2>
            <p class='p-secondary'>Fill out the form below and we'll send you an email with reset password instructions.</p>
            <div class='field'>
              <input
                name="email"
                type="email"
                value={email}
                onChange={event => setEmail(event.target.value)}
                required
                placeholder='Email address'
                title="Enter your email address"
              />
            </div>

            <div style={{marginTop: '60px'}}>
              <ButtonPrimary
                value={'Send Reset Password Instructions'}
                loading={buttonLoading}
                buttonClass={'button tertiary'}
              />

            </div>


            <div class='start-link-container'>
              <p>Already have an account?</p>
              <p>
                <a href='/login'>Login to your FLOW Administrator account</a>
              </p>
            </div>

          </form>

          <div style={{height: '80px', width: '1px'}}></div>

        </div>
      </div>
    </div>
  );
}

export default ForgotPasswordScreen;