import { useState, useEffect } from 'react';


function Slider(props) {

  // Define variables
  const value = props.value;
  const setValue = props.setValue;
  const min = props.min || 1;
  const max = props.max || 100;


  return (
    <input
      className='range-slider-input'
      type='range'
      value={value}
      min={min}
      max={max}
      onChange={(event) => setValue(event.target.value)}
    />
  );
}

export default Slider;