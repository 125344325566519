import Step from "./step";


function Steps(props) {

  // Define variables
  let step = props.step;
  if( step === 'purchase' ) { step = 3 }


  return (
    <div class='onboarding-steps-container'>
      <Step thisStep={1} title={'Organization Details'} currentStep={step} />
      <Step thisStep={2} title={'Group Details'} currentStep={step} />
      <Step thisStep={3} title={'Plan Options'} currentStep={step} />
      <Step thisStep={4} title={'Done'} currentStep={step} />
    </div>
  );
}

export default Steps;