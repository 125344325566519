function WelcomeCard(props) {

  // Define variables
  const groupName = props.groupName;
  // TO DO - school / business language


  return (
    <div class='flex-column border-element-round welcome-card-container'>
      <h2 class='outfit' style={{marginBottom: '30px'}}>You're All Set Up 🎉</h2>
      <p >Your account has been configured and your first Group is ready to go.</p>
      <p>View your {groupName} Group to start inviting your students.</p>
    </div>
  );
}

export default WelcomeCard;