import { useEffect, useState } from 'react';

import { getCourses } from '../../utilities/auth/authAPI';
import CourseChooseButton from './courseChooseButton';


function CourseChooser(props) {

  // Define variables
  const [courseList, setCourseList] = useState([]);
  // const courseList = props.courseList;
  // const setCourseList = props.setCourseList;
  const handleChange = props.handleChange;
  const selectedId = props.selectedId;
  const studentWording = props.studentWording;


  // Component load actions
  useEffect(() => {
    if( courseList.length === 0 ) {
      getCourses(getCourses).then((response) => {
        if( response?.data?.courses ) {
          setCourseList(response.data.courses);
        } else {
          // toast.error(response.error);
        }
      });
    }
  }, []);


  return (
    <div class='course-chooser-wrapper'>
      <h3 class='v2'>Courses</h3>
      <p class='p-secondary'>Choose a Course for your {studentWording} to take</p>

      <div class='flex-column border-element-round padding-primary course-chooser-container'>
        {courseList.map((course, index) =>
          <CourseChooseButton key={`choose-course-${index}`} course={course} handleChange={handleChange} selectedId={selectedId} />
        )}
      </div>
    </div>
  );
}

export default CourseChooser;