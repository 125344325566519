import React from 'react';
import { useSelector } from 'react-redux';
import { useNavigate, useLocation } from 'react-router-dom';

import Header from '../utilities/header';
import Footer from '../utilities/footer';
import OrganizationCard from '../utilities/organizationCard';
import GroupCard from './groupCard';
import ButtonSecondary from '../utilities/buttonSecondary';
import LessonData from './lessonData';
import LearnerStats from './learnerStats';
import WelcomeCard from './welcomeCard';

import pluralize from '../../helperFunctions/pluralize';


function GroupsScreen() {

  // Define variables
  const navigate = useNavigate();
  const profileData = useSelector((state) => state.profile);
  const purchaseData = profileData.purchaseData;
  const inviteData = profileData.inviteData;

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const accountCreated = searchParams.get('account_created');

  let lessonsStarted = 0;
  let lessonsCompleted = 0;
  let averageRetries = 0;
  let averageScore = 0;
  let lessonOverTimeData;

  if( profileData.activityData ) {
    lessonsStarted = profileData.activityData.lessonsStarted;
    lessonsCompleted = profileData.activityData.lessonsCompleted;
    averageRetries = profileData.activityData.averageRetries;
    averageScore = profileData.activityData.averageScore;
    lessonOverTimeData = profileData.activityData.lessonOverTimeData;
  }


  // Screen componets
  let addGroupElement;
  if( profileData.role === 'superAdmin' ) {
    addGroupElement = (
      <ButtonSecondary
        value={'Add Group'}
        handleButtonClick={() => navigate('/groups/new')}
      />
    );
  }

  let blankStateElement;
  if( profileData.groups.length === 0 ) {
    blankStateElement = (
      <div class='flex-column border-element-round padding-secondary' style={{textAlign: 'center'}}>
        <br />
        <p><strong>There are not any Groups yet</strong></p>
        <p>Create your first group by clicking the Add Group button above</p>
        <br />
      </div>
    );
  }

  let lessonGraphElement;
  if( lessonOverTimeData && !accountCreated && lessonsStarted != 0 ) {
    lessonGraphElement = (
      <div class='flex-row groups-charts-container' style={{alignItems: 'stretch', gap: '30px', marginBottom: '90px'}}>
        <LessonData
          data={lessonOverTimeData}
          lessonsStarted={lessonsStarted}
          lessonsCompleted={lessonsCompleted}
          averageRetries={averageRetries}
          averageScore={averageScore}
        />
        <LearnerStats activityData={profileData.activityData} />
      </div>
    );
  }

  let welcomeElement;
  if( accountCreated ) {
    welcomeElement = <WelcomeCard groupName={profileData.groups[0].name} />;
  }

  let purchaseCountElement;
  if( purchaseData ) {
    // purchaseCountElement = <p class='p-secondary' style={{marginTop: '5px'}}>{purchaseData.total} Premium {pluralize(purchaseData.total, 'seat')} purchased, {purchaseData.used} used</p>;
    purchaseCountElement = (
      <p class='p-secondary' style={{marginTop: '5px'}}>
        {purchaseData.used} of {purchaseData.total} Premium seats used
      </p>
    );
  }

  let inviteElement;
  if( inviteData ) {
    inviteElement = <p style={{marginTop: '5px'}}>{inviteData.invitesAccepted} invites accepted ( {inviteData.invitesAcceptedPercent} )</p>;
  }


  return(
    <div class='flex-column'>

      <Header />
      <OrganizationCard />

      <div class='body-container'>

        {lessonGraphElement}

        <div>
          <div class='flex-row' style={{marginBottom: '15px'}}>
            <div>
              <h2>Groups</h2>
              {purchaseCountElement}
              {inviteElement}
            </div>
            {addGroupElement}
          </div>

          {blankStateElement}

          <div class='flex-row flex-wrap group-cards-container'>
            {profileData.groups.map((group, index) =>
              <GroupCard
                group={group}
                key={index}
                navigate={navigate}
              />
            )}
          </div>

          {welcomeElement}
        </div>

        <div style={{width: '100%', marginTop: '90px', marginBottom: '60px'}}>
          <h2>Resources</h2>
          <br />
          <p>
            <a href='https://files.flowspeak.io/files/resources/FLOW%20Administrator%20Manual.pdf' target='_blank'>FLOW Administrator Manual</a>
          </p>
        </div>

      </div>

      <Footer />

    </div>
  );
}

export default GroupsScreen;