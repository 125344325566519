import { useState } from "react";

import toast from 'react-hot-toast';
import apiCall from "../../helperFunctions/apiCall";
import { createInvite } from "../utilities/auth/authAPI";
import ButtonPrimary from "../utilities/buttonPrimary";


function InviteLearners(props) {

  // Define variables
  const [payload, setPayload] = useState({id: props.groupId});
  const [buttonLoading, setButtonLoading] = useState(false);
  const learnerCount = props.learnerCount;
  const inviteInformation = props.inviteInformation;
  const premiumSeatsAvailable = inviteInformation.premiumSeatsAvailable;
  const options = inviteInformation.options;


  // Define functions
  function handleChange(value, field) {
    setPayload({...payload, [field]: value});
  }

  function handleSubmit(event) {
    event.preventDefault();

    if( !payload.email || !payload.duration ) {
      toast.error('Please enter an email and select a Subscription');
      return;
    }

    setButtonLoading(true);


    apiCall(createInvite, payload).then((response) => {
      if( response.success ) {
        window.location.reload();
      } else {
        toast.error(response.error);
      }
    }).finally(() => {
      setTimeout(() => setButtonLoading(null), 1000);
    });

  }


  // Screen components
  let blankStateElement;
  if( learnerCount === 0 ) {
    blankStateElement = (
      <div class='flex-column' style={{marginBottom: '45px'}}>
        <h4 class='outfit'>Invite your first Learner!</h4>
        <p>Now that your Group is set up, it's time to invite your learners. </p>
      </div>
    );
  }

  let premiumMessage;
  if( premiumSeatsAvailable ) {
    premiumMessage = (
      <div class='invite-learners-sets-message'>
        <p class='p-secondary'>You have {premiumSeatsAvailable} Premium seats available.</p>
      </div>
    );
  } else {
    premiumMessage = (
      <div class='invite-learners-sets-message'>
        <p class='p-secondary'>You don't have any Premium seats available.</p>
        <p class='p-secondary'>
          <a href='/seats'>Purchase Premium seats so you can give them to your learners.</a>
        </p>
      </div>
    );
  }


  return (
    <div class='invite-learners-wrapper'>
      <h3>Invite Learners</h3>
      <p>Invited learners will receive an email with a link to sign up for FLOW Speak. They will be automatically assigned to this group.</p>

      <div class='border-element-round invite-learners-container'>
        {blankStateElement}

        <form class='flex-row' onSubmit={(event) => handleSubmit(event)} style={{alignItems: 'flex-start'}}>

          <div class='flex-row invite-learners-left' style={{alignItems: 'flex-start', gap: '30px'}}>
            <div>
              <label>Learner email address</label>
              <input
                required
                type='email'
                value={payload.email}
                onChange={event => handleChange(event.target.value, 'email')}
              />
            </div>

            <div>
              <label>Plan</label>
              <select
                required
                value={payload.duration}
                onChange={event => handleChange(event.target.value, 'duration')}
              >
                <option value=''>Select</option>
                {options.map((option, index) =>
                  <option
                    key={`invite-id-${option.id}`}
                    value={option.id}
                  >
                    {option.value}
                  </option>
                )}
              </select>

              {premiumMessage}
            </div>
          </div>


          <div>
            <label>&nbsp;</label>
            <ButtonPrimary
              value={'Send Invite'}
              loading={buttonLoading}
              buttonClass={'button tertiary'}
            />
          </div>

        </form>

      </div>
    </div>
  );
}

export default InviteLearners;