import PricingPremium from './pricingPremium';


function Pricing(props) {

  // Define variables
  const subscription = props.subscription;
  const handlePriceChange = props.handlePriceChange;
  const studentWording = props.studentWording;


  // Screen components
  let bodyElement = (
    <div>
      <p>Make a selection above to see pricing details</p>
    </div>
  );

  if( subscription === 'community' ) {
    bodyElement = (
      <div>
        <p>FLOW Speak offers the Community plan at no cost</p>
        <p>After you finish creating your account, you will be able to invite your students</p>
      </div>
    );
  }

  if( subscription === 'premium' ) {
    bodyElement = (
      <PricingPremium
        handlePriceChange={handlePriceChange}
        studentWording={studentWording}
      />
    );
  }


  return (
    <div class='onboarding-big-button' style={{maxWidth: '100%'}}>
      {bodyElement}
    </div>
  );
}

export default Pricing;