import pluralize from '../../helperFunctions/pluralize';


function GroupCard(props) {

  // Define variables
  const navigate = props.navigate;
  const group = props.group;
  const name = group.name;
  const totalSeats = group.totalSeats;
  const invitesAccepted = group.invitesAccepted;
  const invitesAcceptedPercent = group.invitesAcceptedPercent;
  const curriculum = group.curriculum;


  // Screen components
  let totalSeatsElement;
  if( totalSeats ) {
    totalSeatsElement = <small> ( of {totalSeats} seats allocated )</small>;
  }

  let inviteElement;
  if( invitesAccepted && invitesAcceptedPercent ) {
    inviteElement = <p class='color-secondary'>{invitesAccepted} invites accepted ( {invitesAcceptedPercent} )</p>;
  }

  let learnersElement;
  let lessonCountElement;
  if( group.learnerCount === 0 ) {
    learnersElement = <p class='color-secondary'>No Learners yet</p>;
  } else {
    learnersElement = (
      <p class='color-secondary'>
        {group.learnerCount} {pluralize(group.learnerCount, 'Learner')}
        {totalSeatsElement}
      </p>
    );

    lessonCountElement = (
      <p class='color-secondary'>
        {group.lessonsCompleted} {pluralize(group.lessonsCompleted, 'Lesson')} completed
      </p>
    );
  }

  let curriculumElement;
  if( curriculum ) {
    curriculumElement =  <p class='color-secondary'>Curriculum: {curriculum}</p>
  }


  return(
    <button
      class='border-element-round padding-secondary group-card'
      onClick={() => navigate(`/groups/${group.id}`)}
      type={'button'}
    >

      <h3 style={{marginBottom: '15px'}}>{name}</h3>

      {learnersElement}
      {inviteElement}
      {lessonCountElement}
      {curriculumElement}
    </button>
  );
}

export default GroupCard;