import { useState, useEffect } from 'react';

import PricingOption from "./pricingOption";
import Slider from '../../utilities/slider';


function PricingPremium(props) {

  // Define variables
  const [duration, setDuration] = useState(12);
  const [count, setCount] = useState(10);
  const [total, setTotal] = useState(null);
  const handlePriceChange = props.handlePriceChange;
  const studentWording = props.studentWording;


  // Component load actions
  useEffect(() => {
    handlePriceChange(duration, count);

    calculateTotal();
  }, [duration, count]);


  // Define functions
  function calculateTotal() {
    let cost = 12;
    if( duration === 3 ) { cost = 32 }
    if( duration === 6 ) { cost = 59 }
    if( duration === 12 ) { cost = 97 }
    // TO DO - account for volume discounts

    const total = cost * count;
    setTotal(total);
  }

  return (
    <div style={{textAlign: 'center'}}>

      <div style={{marginTop: '15px'}}>
        <h4 class='v2'>How long will your {studentWording} use FLOW Speak?</h4>
        <div class='flex-row onboarding-pricing-buttons-container'>
          <PricingOption duration={1} title={'1 Month'} cost={'12'} selected={duration === 1} setDuration={setDuration} />
          <PricingOption duration={3} title={'3 Months'} cost={'32'} discount={'10%'} selected={duration === 3} setDuration={setDuration} />
          <PricingOption duration={6} title={'6 Months'} cost={'59'} discount={'18%'} selected={duration === 6} setDuration={setDuration} />
          <PricingOption duration={12} title={'12 Months'} cost={'97'} discount={'33%'} selected={duration === 12} setDuration={setDuration} />
        </div>
      </div>

      <div style={{marginTop: '30px'}}>
        <h4 class='v2'>How many {studentWording === 'team' ? 'team members' : 'students'} do you have?</h4>
        <p class='p-secondary' style={{marginTop: '-5px'}}>
          <small><i>Don't worry, you can change this later</i></small>
        </p>

        <input
          type='number'
          class='subscription-count-input'
          min={1}
          max={100}
          value={count}
          onChange={(event) => setCount(event.target.value)}
        />
      </div>

      <div class='flex-column onboarding-price-slider-container'>
        <Slider value={count} setValue={setCount} />
        <div class='flex-row slider-values-container'>
          <span>1</span>
          <span>10</span>
          <span>20</span>
          <span>30</span>
          <span>40</span>
          <span>50</span>
          <span>60</span>
          <span>70</span>
          <span>80</span>
          <span>90</span>
          <span>100</span>
        </div>
      </div>


      <div style={{marginTop: '45px'}}>
        <h4 class='v2'>Total</h4>
        <input
          type='text'
          class='subscription-total-input'
          value={`$${total}`}
          disabled
        />
      </div>


      <p class='p-secondary' style={{marginTop: '30px', lineHeight: '1.2rem', textAlign: 'center'}}>
        <small>
          Is your Organization going to purchase or do you need more than 100 seats?
          <br />
          Contact <a href='mailto:achieve@flowspeak.io' class='volume-pricing-link'>achieve@flowspeak.io</a> to talk with our Customer Success team.
        </small>
      </p>

    </div>
  );
}

export default PricingPremium;