import React from 'react';
import { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { useLocation } from 'react-router-dom';


import toast from 'react-hot-toast';
import apiCall from '../../helperFunctions/apiCall';
import { getProfile } from '../utilities/auth/authAPI';
import { Loading } from './helpers';
import profileSlice from '../../store/profile';
import pluralize from '../../helperFunctions/pluralize';


function OrganizationCard(props) {

  // Dispatch functions
  const dispatch = useDispatch();

  function initialPopulate(data) {
    dispatch(profileSlice.actions.initialPopulate(data));
  }


  // Define variables
  const navigate = useNavigate();
  const location = useLocation();
  const passedOrganizationId = location?.state?.organizationId;

  const { id } = useParams();
  const profileData = useSelector((state) => state.profile);
  const [orgSelect, setOrgSelect] = useState(false);
  const [loading, setLoading] = useState(false);
  const profileInitalized = profileData.initalized;
  // const inviteData = profileData.inviteData;
  // const showAdditionlInfo = props.showAdditionlInfo;
  const path = window.location.pathname;
  const isHome = path === '/';


  // Component load actions
  useEffect(() => {

    if( !profileInitalized ) {
      getProfileData(passedOrganizationId, id);
    }

  }, []);


  // Define functions
  function getProfileData(organizationId, organizationGroupId) {

    const payload = {}

    if( organizationId ) {
      payload.organization_id = organizationId;
    }

    if( organizationGroupId ) {
      payload.organization_group_id = organizationGroupId;
    }

    apiCall(getProfile, payload).then((response) => {
      if( response.success ) {
        initialPopulate(response.data);
        setOrgSelect(false);
        setLoading(false);
      } else {
        if( response?.data?.onboardingStep ) {
          navigate('/onboard');
        } else {
          toast.error(response.error);
        }
      }
    });
  }

  function changeOrg(id) {
    setLoading(true);
    getProfileData(id);
  }


  // Screen componets
  // let seatsElement;
  // if( showAdditionlInfo && profileData.seatsEnabled ) {
  //   seatsElement = <p style={{marginTop: '15px'}}>{profileData.seatsUsed} of {profileData.seatsTotal} seats</p>;
  // }

  // let inviteElement;
  // if( showAdditionlInfo && inviteData ) {
  //   inviteElement = <p style={{marginTop: '5px'}}>{inviteData.invitesAccepted} invites accepted ( {inviteData.invitesAcceptedPercent} )</p>;
  // }

  // let planElement;
  // if( showAdditionlInfo && profileData.datesEnabled ) {
  //   planElement = (
  //     <div>
  //       <p>Plan start date: {profileData.planStartDate}</p>
  //       <p>Plan end date: {profileData.planEndDate}</p>
  //     </div>
  //   );
  // }

  let changeButtonElement;
  if( isHome && profileData?.organizations?.length > 1 ) {
    changeButtonElement = (
      <button
        class='org-change-button'
        onClick={() => setOrgSelect(true)}
      >
        <p>change</p>
      </button>
    );
  }

  let orgElement = (
    <div class='flex-row' style={{justifyContent: 'flex-start', alignItems: 'baseline'}}>
      <button onClick={() => navigate('/')}>
        <h1>{profileData.organizationName}</h1>
      </button>
      {changeButtonElement}
    </div>
  );
  if( orgSelect ) {
    orgElement = (
      <div>

        <select onChange={(event) => changeOrg(event.target.value)}>
          {profileData.organizations.map((organization, index) =>
            <option
              key={`org-id-${organization.id}`}
              value={organization.id}
              selected={profileData.organizationId === organization.id}
            >
              {organization.name}
            </option>
          )}
        </select>

        <button
          class='org-change-button'
          onClick={() => setOrgSelect(false)}
        >
          <p>cancel</p>
        </button>

      </div>
    );
  }

  let loadingElement;
  if( loading ) {
    loadingElement = (
      <div class='loading-wrapper'>
        <div class='loading-box'>
          <Loading />
        </div>
      </div>
    );
  }


  return(
    <div class='organization-card flex-column'>
      <div class='header-container'>
        {orgElement}
        {/* {seatsElement} */}
        {/* {inviteElement} */}
        {/* {planElement} */}
        {loadingElement}
      </div>
    </div>
  );
}

export default OrganizationCard;