import React from 'react';
import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate, useLocation } from 'react-router-dom';

import toast from 'react-hot-toast';
import apiCall from '../../helperFunctions/apiCall';
import { getSeats, getTransactions } from '../utilities/auth/authAPI';
import Header from '../utilities/header';
import Footer from '../utilities/footer';
import ButtonPrimary from '../utilities/buttonPrimary';
import OrganizationCard from '../utilities/organizationCard';
import SeatCount from './seatCount';
import PricingPremium from '../start/onboarding/pricingPremium';
import PurchaseSeats from '../utilities/stripe/purchaseSeats';
import TransactionsList from './transactionsList';

import onboardWording from '../../helperFunctions/onboardWording';


function SeatsScreen() {

  // Define variables
  const location = useLocation();
  const navigate = useNavigate();
  const isSuperAdmin = useSelector((state) => state.profile.role) === 'superAdmin';
  const organizationId = useSelector((state) => state.profile.organizationId);
  const [purchasePayload, setPurchasePayload] = useState({count: 10, duration: 12});
  const [purchaseStep, setPurchaseStep] = useState(1);
  const [buttonLoading, setButtonLoading] = useState(false);
  const [data, setData] = useState({});
  const [transactions, setTransactions] = useState([]);

  const studentWording = onboardWording(data.orgType, 'student');


  // Component load actions
  useEffect(() => {
    if( organizationId ) {
      if( !isSuperAdmin ) { navigate('/') }

      getSeatsInfo();
      getTransactionInfo();


      // Look for success or error info
      const searchParams = new URLSearchParams(location.search);
      const success = searchParams.get('success');
      const errorMessage = searchParams.get('error');

      if( success ) {
        if( errorMessage ) {
          toast.error(errorMessage, {duration: 10000});
        } else if( success === 'true' ) {
          toast.success('Seats successfully purchased');
        }
      }
    }

  }, [organizationId]);


  // Define functions
  function getSeatsInfo() {

    const payload = {organizationId: organizationId}

    apiCall(getSeats, payload).then((response) => {
      if( response.success ) {
        setData(response.data);
      } else {
        toast.error(response.error);
      }
    });
  }

  function getTransactionInfo() {
    const payload = {organizationId: organizationId}

    apiCall(getTransactions, payload).then((response) => {
      if( response.success ) {
        setTransactions(response.data);
      } else {
        toast.error(response.error);
      }
    });
  }

  function handlePriceChange(duration, count) {
    setPurchasePayload({...purchasePayload, duration: duration, count: count });
  }

  function handleSubmit(event) {
    event.preventDefault();
    setButtonLoading(true);

    setPurchaseStep(2);

    setTimeout(() => {
      setButtonLoading(false);
    }, 1000);
  }


  // Screen components
  let purchaseElement;
  if( purchaseStep === 1 ) {
    purchaseElement = (
      <form class='flex-column ' onSubmit={handleSubmit}>
        <PricingPremium handlePriceChange={handlePriceChange} studentWording={studentWording} />

        <div style={{margin: '45px'}}>
          <ButtonPrimary
            value={'Next'}
            loading={buttonLoading}
            buttonClass={'button tertiary'}
          />
        </div>
      </form>
    );
  } else {
    purchaseElement = (
      <div>
        <button
          type='button'
          class='flex-row'
          onClick={() => setPurchaseStep(1)}
        >
          <span class='icon icon-chevron-left' style={{width: '16px', opacity: '.8'}}></span>
          <p class='p-secondary' style={{marginBottom: 'initial', marginLeft: '5px'}}>Back</p>
        </button>

        <div class='flex-column'>
          <PurchaseSeats count={purchasePayload.count} duration={purchasePayload.duration} location={'seats'} />
        </div>
      </div>
    );
  }


  return(
    <div class='flex-column'>

      <Header />
      <OrganizationCard />

      <div class='body-container'>

          <h2>Seats</h2>

          <div class='flex-row group-details-container' style={{justifyContent: 'flex-start', gap: '60px'}}>
            <SeatCount duration={1} data={data['1']} />
            <SeatCount duration={3} data={data['3']} />
            <SeatCount duration={6} data={data['6']} />
            <SeatCount duration={12} data={data['12']} />
          </div>

          <h2 style={{marginTop: '90px'}}>Buy Seats</h2>
          <div class='group-details-container' style={{minHeight: '650px'}}>
            {purchaseElement}
          </div>

          <h2 style={{marginTop: '90px', marginBottom: '15px'}}>Purchases</h2>
          <TransactionsList transactions={transactions} />

      </div>

      <Footer />

    </div>
  );
}

export default SeatsScreen;