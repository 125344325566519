function SeatCount(props) {

  // Define variables
  const duration = props.duration;
  const data = props.data || {};
  const total = data.total || 0;
  const used = data.used;


  // Screen components
  let inUseElement;
  if( used ) {
    inUseElement = <span>, {used} used</span>;
  }


  return (
    <div>
      <p>
        <strong>{duration} {duration === 1 ? "Month" : "Months"}</strong>
      </p>
      <p>{total} seats purchased{inUseElement}</p>
    </div>
  );
}

export default SeatCount;