import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';

import toast from 'react-hot-toast';
import apiCall from '../../../helperFunctions/apiCall';
import { verifyCheckoutSession } from '../../utilities/auth/authAPI';
let canSubmit = true;


function PurchaseCompleteScreen() {

  // Define variables
  const navigate = useNavigate();
  const { sessionId } = useParams();


  // Component load actions
  useEffect(() => {
    if( canSubmit ) {
      canSubmit = false;

      apiCall(verifyCheckoutSession, {sessionId: sessionId}).then((response) => {
        if( response.success ) {
          navigate('/?account_created=true');
        } else {
          toast.error(response.error);
        }
      });
    }
  }, []);

  return (
    <></>
  );
}

export default PurchaseCompleteScreen;