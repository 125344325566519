import { useState, useEffect } from 'react';

import {loadStripe} from '@stripe/stripe-js';
import {
  EmbeddedCheckoutProvider,
  EmbeddedCheckout
} from '@stripe/react-stripe-js';

import toast from 'react-hot-toast';
import apiCall from '../../../helperFunctions/apiCall.js';
import { createCheckoutSession } from "../auth/authAPI.js";
import { Loading } from '../helpers.jsx';

import { stripePublishableKey } from "./stripeHelpers.js";
const stripePromise = loadStripe(stripePublishableKey);


function PurchaseSeats(props) {

  // Define variables
  const [options, setOptions] = useState(null);
  const count = props.count;
  const duration = props.duration;
  const location = props.location;


  // Component load actions
  useEffect(() => {

    const payload = {
      location: location,
      count: count,
      duration: duration
    }

    apiCall(createCheckoutSession, payload).then((response) => {
      if( response.success ) {
        setOptions(response.data);
      } else {
        toast.error(response.error);
      }
    });

  }, []);


  // Screen components
  let purchaseElement = <Loading />;
  if( options ) {
    purchaseElement = (
      <EmbeddedCheckoutProvider
        stripe={stripePromise}
        options={options}
      >
        <EmbeddedCheckout />
      </EmbeddedCheckoutProvider>
    );
  }


  return (
    <div id="checkout">
      {purchaseElement}
    </div>
  );
}

export default PurchaseSeats;