import PrimeTableSimple from "../utilities/table/primeTableSimple";


function TransactionsList(props) {

  // Define variables
  const transactions = props.transactions || [];

  const receiptBody = (item) => {
    return <a href={item.receiptUrl} target='_blank' rel='noreferrer'>Download</a>;
  }

  const promoCodeBody = (item) => {
    let body;
    if( item.promoCode ) {
      body = <span>{item.promoCode.code} <small>({item.promoCode.value})</small></span>
    }

    return body;
  }

  const columns = [
    {name: 'amountFormatted', display: 'Amount'},
    {name: 'promoCode', display: 'Promo Code', body: promoCodeBody},
    {name: 'quantity', display: 'Quantity'},
    {name: 'duration', display: 'Duration'},
    {name: 'status', display: 'Status'},
    {name: 'last4', display: 'Card'},
    {name: 'created', display: 'Created'},
    {name: 'receiptUrl', display: 'Receipt', body: receiptBody},
  ];


  // Screen compoents
  let blankStateElement;
  if( !transactions || transactions.length === 0) {
    blankStateElement = <p>You have not made any purchases.</p>
  }


  return (
    <PrimeTableSimple
      columns={columns}
      data={transactions}
      // searchPlaceholder={'Search by code, name, or email'}
    />
  );
}

export default TransactionsList;