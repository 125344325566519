import TdView from './tdView';
import TdEditPassword from './tdEditPassword';
import TdRemoveSeat from './tdRemoveSeat';
import TdEditInfo from './tdEditInfo';
import TdResendInvite from './tdResendInvite';
// import ProgressBar from '../progressBar';
import CourseDataCell from './courseDataCell';


function TableLearnersRow(props) {

  // Define variables
  const object = props.object;
  const viewFunction = props.viewFunction || undefined;
  const editPasswordFunction = props.editPasswordFunction || undefined;
  const editInfoFunction = props.editInfoFunction || undefined;
  const removeSeatFunction = props.removeSeatFunction || undefined;
  const resendInviteFunction = props.resendInviteFunction || undefined;
  const courseData = object.courseData;
  const pathwayData = object.pathwayData;
  const showCourseActivityColumn = props.showCourseActivityColumn;
  const inviteData = object.inviteData;
  const invite = inviteData?.invite;


  // Screen components
  let courseDataElement;
  if( showCourseActivityColumn ) {
    courseDataElement = <CourseDataCell courseData={courseData} pathwayData={pathwayData} />;
  }

  let lastLessonCompleteDateText = '---';
  if( object.lastLessonCompleteDate ) {
    lastLessonCompleteDateText = object.lastLessonCompleteDateFormatted;
  }

  let phoneNumberElement;
  if( object.phoneNumber ) {
    phoneNumberElement = <p>{object.phoneNumber}</p>;
  }

  let confidenceMembershipElement;
  let statsElement;
  if( invite ) {

    let durationElement;
    if( inviteData.duration ) {
      durationElement = <i><small>{inviteData.duration} month subscription</small></i>;
    } else if( inviteData.community ) {
      durationElement = <i><small>Community subscription</small></i>;
    }

    statsElement = (
      <div class='table-learner-row-data'>
        <p style={{marginTop: '15px'}}>
          <i>Invite sent</i>
          <br />
          {durationElement}
        </p>
      </div>
    );

  } else {

    confidenceMembershipElement = (
      <div>
        <p>{object.score} Confidence Level</p>
        <p><small><i>{object.subscriptionType} Membership</i></small></p>
      </div>
    );

    statsElement = (
      <div class='table-learner-row-data'>
        <p>{object.lessonsCompleted} completed</p>
        <p class='gray-text'>{object.averageScore} average score</p>
        <p class='gray-text'>{object.averageRetries} average retries</p>
        <p class='gray-text'>
          <small>Last lesson on {lastLessonCompleteDateText}</small>
        </p>
      </div>
    );
  }


  return (
    <div class='flex-row table-learner-row'>

      <div class='flex-row table-learner-row-one'>
        <div class='table-learner-row-data'>
          <p><strong>{object.firstName} {object.lastName}</strong></p>
          <p class='table-learner-row-email'>{object.email}</p>
          {phoneNumberElement}
          {confidenceMembershipElement}
        </div>

        {courseDataElement}

        {statsElement}
      </div>

      <div class='table-learner-row-actions'>
        {viewFunction && !invite ? <TdView id={object.id} viewFunction={viewFunction} /> : null }
        {editPasswordFunction && !invite ? <TdEditPassword object={object} editPasswordFunction={editPasswordFunction} /> : null }
        {editInfoFunction ? <TdEditInfo object={object} editInfoFunction={editInfoFunction} /> : null }
        {removeSeatFunction && !invite ? <TdRemoveSeat id={object.id} removeSeatFunction={removeSeatFunction} /> : null }
        {resendInviteFunction && invite ? <TdResendInvite id={object.id} resendInviteFunction={resendInviteFunction} /> : null }
      </div>

    </div>
  );
}

export default TableLearnersRow;