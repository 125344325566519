import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import toast from 'react-hot-toast';
import apiCall from '../../../helperFunctions/apiCall';
import { getOngoardingProfile } from '../../utilities/auth/authAPI';

import { Loading } from '../../utilities/helpers';
import Header from '../../utilities/header';
import Steps from './steps';
import StepOne from './stepOne';
import StepTwo from './stepTwo';
import StepThree from './stepThree';
import OnboardPurchase from './onboardPurchase';


function OnboardScreen() {

  // Define variables
  const navigate = useNavigate();
  const [data, setData] = useState({onboardingStep: 0});
  const [orgType, setOrgType] = useState('general');
  // const firstName = data.firstName;
  const onboardingStep = data.onboardingStep;


  // Component load actions
  useEffect(() => {

    // Look up onboarding step
    apiCall(getOngoardingProfile).then((response) => {
      if( response.success ) {
        if( response.data.onboardingStep === 4 ) {
          navigate('/');
        } else {
          setData(response.data);

          if( response.data.orgType ) {
            setOrgType(response.data.orgType)
          }
        }
      } else {
        toast.error(response.error);
      }
    });

  }, []);


  // Define functions
  function updateStep(newStep) {
    setData({...data, onboardingStep: newStep});
  }

  function updateStepPurchase(payload) {
    setData({...data, onboardingStep: 'purchase', count: payload.count, duration: payload.duration});
  }


  // Screen components
  let bodyElement = (
    <div class='flex-column' style={{width: '100%'}}>
      <Loading />
    </div>
  );

  if( onboardingStep === 1 ) {
    bodyElement = <StepOne updateStep={updateStep} setOrgType={setOrgType} />;
  } else if( onboardingStep === 2 ) {
    bodyElement = <StepTwo updateStep={updateStep} orgType={orgType} />;
  } else if( onboardingStep === 3 ) {
    bodyElement = <StepThree updateStepPurchase={updateStepPurchase} orgType={orgType} />;
  } else if( onboardingStep === 'purchase' ) {
    bodyElement = <OnboardPurchase updateStep={updateStep} data={data} orgType={orgType} />;
  }


  return (
    <div class='flex-row start-wrapper'>

      <div class='flex-column start-left-column onboarding'>
        <div class='start-left-inner'>
          <Header />
          <Steps step={onboardingStep} />
        </div>
      </div>

      <div class='start-right-column onboarding'>
        <div class='flex-column start-right-inner'>

          <div>
            <h2>Welcome to FLOW Speak!</h2>
            <p>Let's set up your account</p>
          </div>

          {bodyElement}

          <div style={{height: '80px', width: '1px'}}></div>

        </div>
      </div>
    </div>
  );
}

export default OnboardScreen;