function LearnerStats(props) {

  // Define variables
  const activityData = props.activityData;
  const totalLearners = activityData.totalLearners;
  const totalLearningTime = activityData.totalLearningTime;
  const totalLearningMinutes = Math.round(totalLearningTime / 60);


  // Screen components
  let learningTimeElement;
  if( totalLearningTime > 200 ) {
    let totalLearningHours = (totalLearningMinutes / 60).toFixed(1);
    if( totalLearningHours > 30 ) {
      totalLearningHours = Math.round(totalLearningHours);
    }

    learningTimeElement = (
      <div class='flex-column stat-box'>
        <p class='stat-label'>Learning Time</p>
        <h4>{parseFloat(totalLearningHours)}</h4>
        <p><i><small>hours</small></i></p>
      </div>
    );
  } else {
    learningTimeElement = (
      <div class='flex-column stat-box'>
        <p class='stat-label'>Learning Time</p>
        <h4>{totalLearningMinutes}</h4>
        <p><i><small>minutes</small></i></p>
      </div>
    );
  }


  return (
    <div class='learner-stats-container' style={{width: '60%', boxSizing: 'border-box' }}>
      <div class='flex-row' style={{marginBottom: '5px'}}>
        <p><small>&nbsp;</small></p>
      </div>

      <div class='flex-row border-element-round padding-primary stat-box-container'>

        <div class='flex-column stat-box'>
          <p class='stat-label'>Learners</p>
          <h4>{totalLearners}</h4>
        </div>

        {learningTimeElement}
      </div>
    </div>
  );
}

export default LearnerStats;