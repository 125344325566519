import { useNavigate, useLocation } from 'react-router-dom';


function Nav(props) {

  // Define variables
  const navigate = useNavigate();
  const location = useLocation();
  const pathname = location.pathname;
  const profileData = props.profileData;
  const isSuperAdmin = props.isSuperAdmin;
  const invitesEnabled = profileData.invitesEnabled;


  // Define functions
  function logout() {
    localStorage.removeItem("jwt-token");
    window.location = '/login';
  }


  // Screen componets
  let staffMembersElement;
  let seatsElement;
  if( isSuperAdmin ) {

    staffMembersElement = (
      <button
        onClick={() => navigate('/staff')}
        style={{padding: '15px'}}
        class={`link ${pathname === '/staff' ? 'active' : null}`}
      >Staff</button>
    );

    if( invitesEnabled ) {
      seatsElement = (
        <button
          onClick={() => navigate('/seats')}
          style={{padding: '15px'}}
          class={`link ${pathname === '/seats' ? 'active' : null}`}
        >Seats</button>
      );
    }
  }


  return(
      <div class='flex-row nav'>

        <div>

          <button
            onClick={() => navigate('/')}
            style={{padding: '15px'}}
            class={`link ${(pathname === '/' || pathname.includes('/group')) ? 'active' : null}`}
          >Groups</button>

          <button
            onClick={() => navigate('/assessments')}
            style={{padding: '15px'}}
            class={`link ${pathname === '/assessments' ? 'active' : null}`}
          >Assessments</button>

          {staffMembersElement}
          {seatsElement}

        </div>

        <div class='flex-row go-to-flow'>
          <a
            href='https://app.flowspeak.io'
            title='Go to FLOW Speak'
            class='flow-speak-link'
            target='_blank'
            rel='nofollow'
          >
            Go to FLOW Speak
          </a>
        </div>

        <div class='flex-row profile-name'>
          <span>{profileData.firstName}</span>

          <button
            class='icon icon-logout'
            style={{marginLeft: '15px'}}
            onClick={logout}
            title={'Logout'}
          />
        </div>
      </div>
  );
}

export default Nav;