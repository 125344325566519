function TdResendInvite(props) {

  // Define variables
  const id = props.id;
  const resendInviteFunction = props.resendInviteFunction;


  return(
    <td class='td-icon'>
      <button
        onClick={() => resendInviteFunction(id)}
        class='icon icon-resend'
        title='Resend invite email'
      />
    </td>
  );
}

export default TdResendInvite;