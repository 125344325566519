function PricingOption(props) {

  // Define variables
  const duration = props.duration;
  const title = props.title;
  const cost = props.cost;
  const selected = props.selected;
  const setDuration = props.setDuration;
  const discount = props.discount;


  // Screen components
  let discountElement = <p class='pricing-sub-text'>&nbsp;</p>;
  if( discount ) {
    discountElement = <p class='pricing-sub-text discount-label'>{discount} off</p>;
  }


  return (
    <div class='flex-column'>
      <button
        type={'button'}
        class={`onboarding-price-button ${selected ? 'selected' : ''}`}
        onClick={() => setDuration(duration)}
      >
        {title}
      </button>
      <p class='pricing-sub-text'>${cost}/student</p>
      {discountElement}
    </div>
  );
}

export default PricingOption;