let publishableKey;

if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
  publishableKey = 'pk_test_51KJuYTIs167BP3av2cS6cGzD5JS5HcRRtYxkLHTGRi2eGEB0ge0nKrcOhFuXdrfULGrdZrtNoPwg87EGHsBO88FW00pFit15wL';
} else {

  const host = window.location.host;
  const onStaging = host.search('--flow-speak-admin.netlify.app') > 0;

  if( onStaging ) {
    publishableKey = 'pk_test_51KJuYTIs167BP3av2cS6cGzD5JS5HcRRtYxkLHTGRi2eGEB0ge0nKrcOhFuXdrfULGrdZrtNoPwg87EGHsBO88FW00pFit15wL';
  } else {
    publishableKey = 'pk_live_51KJuYTIs167BP3avgra6G9o2W8Zcr7IU26HAu388HO5uqMoMJiFZx0A5IBMUPqrZOcXqfo6blKDbohjBHLW5Rah800K4D5pCHi';
  }

}

export const stripePublishableKey = publishableKey;