import React from "react";

function ButtonPrimary(props) {

  const buttonId = props.id || `button-${Date.now()}`;
  let value = props.value || 'Submit';
  const type = props.type || 'submit';
  let buttonClass = props.buttonClass || 'button primary';
  const handleButtonClick = props.handleButtonClick || undefined;
  const loading = props.loading || false;
  let disabled = props.disabled || false;
  const title = props.title || `Tap to ${value}`;

  if( disabled ) {
    buttonClass = buttonClass + ' disabled';
  }

  if( loading ) {
    buttonClass = buttonClass + ' is-loading';
    value = '';
    disabled = true;
  }

  return (
    <div class="button-wrapper">
      <button
        id={buttonId}
        type={type}
        class={buttonClass}
        value={value}
        onClick={handleButtonClick}
        title={title}
        disabled={disabled}
      >{value}</button>
    </div>
  );
}

export default ButtonPrimary;