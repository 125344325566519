import React from 'react';
import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import toast from 'react-hot-toast';
import { isAuthed, saveToken } from '../utilities/auth/tokenHelpers';
import apiCall from '../../helperFunctions/apiCall';
import { signin } from '../utilities/auth/authAPI';
import Header from '../utilities/header';
import ButtonPrimary from '../utilities/buttonPrimary';
import InputPassword from '../utilities/forms/inputPassword';


function Login() {

  // Define variables
  const navigate = useNavigate();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [buttonLoading, setButtonLoading] = useState('');


  // Page load actions
  useEffect(() => {

    const loggedIn = isAuthed();
    if( loggedIn ) {
      navigate('/');
    }

  }, []);


  // Define functions
  function handleSubmit(event) {
    event.preventDefault();

    if( !email || !password ) {
      toast.error('Please enter your email and password');
      return;
    }

    setButtonLoading(true);

    const payload = {
      email: email,
      password: password
    };

    apiCall(signin, payload).then((response) => {
      if( response.success ) {
        saveToken(response.data.jwt);
        setTimeout(() => navigate('/'), 400);
      } else {
        toast.error(response.error);
      }
    }).finally(() => {
      setTimeout(() => setButtonLoading(null), 400);
    });
  }


  return(
    <div class='flex-row start-wrapper'>

      <div class='start-left-column'>
        <div class='start-left-inner'></div>
      </div>

      <div class='start-right-column'>
        <div class='flex-column start-right-inner'>

          <Header />

          <form onSubmit={handleSubmit}>

            <h2 class='outfit'>Login</h2>
            <div class='field'>
              <input
                name="email"
                type="email"
                value={email}
                onChange={event => setEmail(event.target.value)}
                required
                placeholder='Email address'
                title="Enter your email address"
              />
            </div>

            <div class='field'>
              <InputPassword
                value={password}
                handleOnChange={setPassword}
              />
            </div>

            <div style={{marginTop: '60px'}}>
              <ButtonPrimary
                value={'Login'}
                loading={buttonLoading}
                buttonClass={'button tertiary'}
              />

              <div class='start-link-container' style={{marginTop: '45px', opacity: '.7'}}>
                <a href='/forgot-password'>Forgot your password?</a>
              </div>
            </div>


            <div class='start-link-container'>
              <p>Don't have an account?</p>
              <p>
                <a href='/register'>Sign up to create your FLOW Administrator account</a>
              </p>
            </div>

          </form>

          <div style={{height: '80px', width: '1px'}}></div>

        </div>
      </div>
    </div>
  );
}

export default Login;