import ProgressBar from "../progressBar";


function CourseUnhas(props) {

  // Define variables
  const courseData = props.courseData;
  const display = courseData.display;
  const status = courseData.status.toLowerCase();


  // Screen components
  let bodyElement;
  if( display ) {

    let courseProgressElement;

    if( status === 'not started') {

      courseProgressElement = (
        <div class='flex-row' style={{justifyContent: 'flex-start'}}>
          <ProgressBar percent={0} />
          <p class="gray-text seconday-info">
            Not started
          </p>
        </div>
      );

    } else if( status === 'active' ) {

      const courseProgressDecimal = courseData.courseLessonsCompleted / courseData.courseLessonTotal;
      const courseProgressPercent = Math.round(100 * courseProgressDecimal);

      courseProgressElement = (
        <div class='flex-row' style={{justifyContent: 'flex-start'}}>
          <ProgressBar percent={courseProgressPercent} />
          <p class="gray-text seconday-info">
            {courseData.courseLessonsCompleted}<span>/</span>{courseData.courseLessonTotal}
          </p>
        </div>
      );

    } else if( status === 'complete' ) {

      courseProgressElement = (
        <div class='flex-row' style={{justifyContent: 'flex-start'}}>
          <ProgressBar percent={100} />
          <p class="gray-text seconday-info">
            completed {courseData.completeDate}
          </p>
        </div>
      );
    }

    let generalEnglishElement;
    if( courseData.firstPartAverage ) {
      generalEnglishElement = (
        <div class='unhas-course-data-general-english'>
          <p class="gray-text seconday-info">
            <span>Weeks 9-12:</span> {courseData.firstPartAverage}% average score
          </p>

          <p class="gray-text seconday-info">
            <span>Weeks 13-16:</span> {courseData.secondPartAverage}% average score
          </p>
        </div>
      );
    }


    // Fill the body element
    bodyElement = (
      <div class='unhas-course-data'>
        <p>{courseData.title}</p>
        {courseProgressElement}
        {generalEnglishElement}
      </div>
    );

  }


  return (
    <>{bodyElement}</>
  )
}

export default CourseUnhas;