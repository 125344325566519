import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import toast from 'react-hot-toast';
import apiCall from '../../../helperFunctions/apiCall';
import { onboardingUpdate } from '../../utilities/auth/authAPI';

import Pricing from './pricing';
import ButtonPrimary from '../../utilities/buttonPrimary';
import onboardWording from '../../../helperFunctions/onboardWording';



function StepThree(props) {

  // Define variables
  const navigate = useNavigate();
  const [payload, setPayload] = useState({step: 3});
  const [buttonDisabled, setButtonDisabled] = useState(true);
  const [buttonLoading, setButtonLoading] = useState(false);
  const updateStepPurchase = props.updateStepPurchase;
  const orgType = props.orgType;

  const studentWording = onboardWording(orgType, 'student');
  const teacherWording = onboardWording(orgType, 'teacher');


  // Define functions
  function handleChange(value, field) {
    setPayload({...payload, [field]: value});
    setButtonDisabled(false);
  }

  function handlePriceChange(duration, count) {
    setPayload({...payload, duration: duration, count: count });
  }

  function handleSubmit(event) {
    event.preventDefault();
    setButtonLoading(true);

    if( payload.subscription === 'premium') {
      updateStepPurchase(payload);
    } else {
      noPurchase();
    }
  }


  function noPurchase() {
    apiCall(onboardingUpdate, {step: 4}).then((response) => {
      if( response.success ) {
        navigate('/?account_created=true');
      } else {
        toast.error(response.error);
      }
    }).finally(() => {
      setTimeout(() => setButtonLoading(null), 400);
    });
  }


  return (
    <form onSubmit={handleSubmit}>

      <div class='field with-bottom-margin'>
        <h3 class='v2'>Subscription</h3>
        <p class='p-secondary'>Which Subscription type do you want for your {studentWording}? </p>

        <div class='flex-row onboarding-big-buttons-wrapper plans'>

            <div class='flex-column onboarding-big-button community'>
              <div>
                <h4>Community</h4>
                <p>$0/month</p>

                <ul>
                  <li>2 lesson per week</li>
                  <li>Basic AI speech scoring</li>
                  <li>Beginner Courses</li>
                  <li>Community feedback</li>
                </ul>
              </div>

              <ButtonPrimary
                type={'button'}
                value={'Select'}
                loading={buttonLoading}
                buttonClass={`button onboarding-select-button ${payload.subscription === 'community' ? 'selected' : ''}`}
                handleButtonClick={() => handleChange('community', 'subscription')}
              />
            </div>

            <div class='onboarding-big-button community sub-text mobile' style={{display: 'none'}}>
            <p class='p-secondary' style={{lineHeight: '1.2rem'}}>
              <small>
                Choose this option if you want your {studentWording} to purchase FLOW Speak on their own
              </small>
            </p>
          </div>


            <div class='flex-column onboarding-big-button premium'>
              <div>
                <h4>Premium</h4>
                <p>$12/month/person</p>

                <ul>
                  <li>Unlimited access to Pathways & Courses</li>
                  <li>Unlimited access to Library of 1,200+ lessons</li>
                  <li>Advanced AI speech scoring</li>
                  <li>Earn badges and certificates</li>
                  <li>Unlimited lesson repetition</li>
                </ul>
              </div>

              <ButtonPrimary
                type={'button'}
                value={'Select'}
                loading={buttonLoading}
                buttonClass={`button onboarding-select-button ${payload.subscription === 'premium' ? 'selected' : ''}`}
                handleButtonClick={() => handleChange('premium', 'subscription')}
              />
          </div>

        </div>

        <div class='flex-row onboarding-big-buttons-wrapper'>
          <div class='onboarding-big-button community sub-text desktop'>
            <p class='p-secondary' style={{lineHeight: '1.2rem'}}>
              <small>
                Choose this option if you want your {studentWording} to purchase FLOW Speak on their own
              </small>
            </p>
          </div>

          <div class='onboarding-big-button premium sub-text'>
            <p>&nbsp;</p>
          </div>

        </div>


        <div class='field with-bottom-margin'>
          <h3 class='v2' style={{marginBottom: '10px'}}>Pricing</h3>
          <Pricing subscription={payload.subscription} handlePriceChange={handlePriceChange} studentWording={studentWording} />
        </div>

      </div>


      <div class='flex-row' style={{justifyContent: 'flex-start', marginTop: '60px'}}>
        <ButtonPrimary
          value={'Continue'}
          disabled={buttonDisabled}
          loading={buttonLoading}
          buttonClass={'button tertiary'}
        />

        <button
          type='button'
          class='skip-button'
          onClick={() => noPurchase()}
        >I'll choose this later</button>
      </div>


      <div style={{marginTop: '90px'}}>
        <h3>What do I get as a <span style={{textTransform: 'capitalize'}}>{teacherWording}</span>?</h3>
        <p>As a {teacherWording}, you get access to our dedicated admin dashboard so you can track your {studentWording === 'students' ? "students'" : "team's"} progress and activity.</p>
        <p style={{marginTop: '15px'}}>Additionally, FLOW Speak provides:</p>

        <ul>
          <li style={{marginBottom: '5px'}}>Tech support via email or WhatsApp</li>
          <li style={{marginBottom: '5px'}}>Your logo on {studentWording === 'students' ? "students'" : "team's"} Home screen</li>
          <li>Learning resources</li>
        </ul>
      </div>

    </form>
  );
}

export default StepThree;