function GroupDetailsShort(props) {

  // Define variables
  const learnerCount = props.learnerCount;
  const totalSeats = props.totalSeats;
  const lessonsCompleted = props.lessonsCompleted;
  const totalLearningHours = props.totalLearningHours;
  const averageScore = props.averageScore;
  const averageRetries = props.averageRetries;


  // Screen components
  let totalSeatsElement;
  if( totalSeats ) {
    totalSeatsElement = <small> ( of {totalSeats} seats )</small>;
  }


  return (
    <div class='flex-row group-details-container'>

      <div class='group-detail-item'>
        {learnerCount} {learnerCount === 1 ? 'Learner' : 'Learners'} {totalSeatsElement}
      </div>

      <div class='group-detail-divider'>&nbsp;</div>

      <div class='group-detail-item'>
        {lessonsCompleted} {lessonsCompleted === 1 ? 'Lesson' : 'Lessons'} completed
      </div>

      <div class='group-detail-divider'>&nbsp;</div>

      <div class='group-detail-item'>
        {parseFloat(totalLearningHours)} hours learning time
      </div>

      <div class='group-detail-divider'>&nbsp;</div>

      <div class='group-detail-item'>
        {averageScore} Average Score
      </div>

      <div class='group-detail-divider'>&nbsp;</div>

      <div class='group-detail-item'>
        {averageRetries} Average Retries
      </div>

    </div>
  );
}

export default GroupDetailsShort;