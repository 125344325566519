function Step(props) {

  // Define variables
  const thisStep = props.thisStep;
  const title = props.title;
  const currentStep = props.currentStep;
  const active = thisStep === currentStep;
  const completed = thisStep < currentStep;


  return (
    <div class={`onboarding-step-container ${active ? 'active' : ''} ${completed ? 'completed' : ''}`}>
      <div class='flex-row' style={{justifyContent: 'flex-start'}}>
        <div class='flex-column onboarding-step-number'>{thisStep}</div>
        <p class='outfit'>{title}</p>
      </div>
      <div class='step-spacer'></div>
    </div>
  );
}

export default Step;