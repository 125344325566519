import PurchaseSeats from '../../utilities/stripe/purchaseSeats.js';


function OnboardPurchase(props) {

  // Define variables
  const data = props.data;
  const count = data.count;
  const duration = data.duration;
  const updateStep = props.updateStep;


  return (
    <div style={{width: '100%', marginTop: '45px'}}>
      <button
        type='button'
        class='flex-row'
        style={{marginBottom: '15px'}}
        onClick={() => updateStep(3)}
      >
        <span class='icon icon-chevron-left' style={{width: '12px', opacity: '.8'}}></span>
        <p class='p-secondary' style={{marginBottom: 'initial', marginLeft: '5px'}}>Back</p>
      </button>

      <h3 class='v2'>Purchase</h3>
      <PurchaseSeats count={count} duration={duration} location={'onboard'} />
    </div>
  );
}

export default OnboardPurchase;