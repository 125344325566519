import { useState, useEffect } from 'react';

import toast from 'react-hot-toast';
import apiCall from '../../../helperFunctions/apiCall';
import { onboardingUpdate } from '../../utilities/auth/authAPI';

import ButtonPrimary from '../../utilities/buttonPrimary';



function StepOne(props) {

  // Define variables
  const [payload, setPayload] = useState({step: 1});
  const [buttonLoading, setButtonLoading] = useState(false);
  const updateStep = props.updateStep;
  const setOrgType = props.setOrgType;


  // Define functions
  function handleChange(value, field) {
    setPayload({...payload, [field]: value});

    if( field === 'organizationType' ) {
      setOrgType(value);
    }
  }

  function handleSubmit(event) {
    event.preventDefault();

    if( !payload.organizationName || !payload.organizationType ) {
      toast.error('Please enter your Organization name and type');
      return;
    }

    setButtonLoading(true);


    apiCall(onboardingUpdate, payload).then((response) => {
      if( response.success ) {
        updateStep(2);
      } else {
        toast.error(response.error);
      }
    }).finally(() => {
      setTimeout(() => setButtonLoading(null), 400);
    });
  }


  return (
    <form onSubmit={handleSubmit}>

      <div class='field with-bottom-margin'>
        <h3 class='v2'>Organization name</h3>
        <p class='p-secondary' style={{maxWidth: '450px'}}>The Organization is the top level element in our system. If you don't have an organization name, you can use your name.</p>
        <input
          name="organizationName"
          type="text"
          value={payload.organizationName}
          onChange={event => handleChange(event.target.value, 'organizationName')}
          required
          placeholder='Organization name'
          title="Enter your Organization's name"
          style={{marginTop: '10px'}}
        />
      </div>

      <div class='field with-bottom-margin'>
        <h3 class='v2'>Organization type</h3>
        <select
          name="organizationType"
          type="text"
          value={payload.organizationType}
          onChange={event => handleChange(event.target.value, 'organizationType')}
          required
          title="Enter your Organization's name"
          style={{marginTop: '10px'}}
        >
          <option value=''>Select</option>
          <option value='Independent teacher'>Independent teacher</option>
          <option value='School'>School</option>
          <option value='University'>University </option>
          <option value='Exchange or Non-profit organization'>Exchange or Non-profit organization </option>
          <option value='Business'>Business </option>
          <option value='Other'>Other </option>
        </select>
      </div>

      <div class='field'>
        <h3 class='v2'>Your position <small>(optional)</small></h3>
        <input
          name="position"
          type="text"
          value={payload.position}
          onChange={event => handleChange(event.target.value, 'position')}
          placeholder='You position'
          title="Enter your your position"
          style={{marginTop: '10px'}}
        />
      </div>

      <div style={{marginTop: '60px'}}>
        <ButtonPrimary
          value={'Continue'}
          loading={buttonLoading}
          disabled={!payload.organizationName || !payload.organizationType}
          buttonClass={'button tertiary'}
        />
      </div>

    </form>
  );
}

export default StepOne;